@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind forms;
@tailwind colors;

@layer base {
    @font-face {
        font-family: Montserrat;
        src: url(assets/fonts/Montserrat-VariableFont_wght.ttf) format("ttf");
    }
}
